import "react-toastify/dist/ReactToastify.css";
import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useAppDispatch } from "../store/store";
import {
  getCurrentSessionId,
  fetchChatSessionHistory,
  fetchChatLog,
  connectWebSocket,
  closeWebSocket,
  getIsWebSocketOpen,
  sendMessage,
} from "../store/slices/chatSlice";
import {
  getIsPaymentCardVisible,
  getIsUserCharged,
  fetchCurrentSubscription,
  fetchUserMessageCount,
} from "../store/slices/subscriptionSlice";
import { checkAuthorization, getToken } from "../store/slices/authSlice";
import NavContent from "../components/NavContent/NavContent";
import SubscriptionModal from "../components/SubscriptionModal/SubscriptionModal";
import Loader from "../components/Loader";
import ChatBox from "../components/ChatBox/ChatBox";
import logo from "../assets/images/Hacker-GPT-KO.png";

const Home = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const token = useSelector(getToken);
  const currentSessionId = useSelector(getCurrentSessionId);
  const isPaymentCardVisible = useSelector(getIsPaymentCardVisible);
  const isUserCharged = useSelector(getIsUserCharged);
  const isWebSocketOpen = useSelector(getIsWebSocketOpen);

  useEffect(() => {
    // Check if the URL has the query parameter 'subscribed=success'
    const urlParams = new URLSearchParams(window.location.search);
    const isSubscribedSuccess = urlParams.get('subscribed') === 'success';
    dispatch(connectWebSocket());
    if (isSubscribedSuccess) {
      // If 'subscribed=success', delay the calls by 3 seconds so stripe webhook can update the database
      setTimeout(() => {
        dispatch(fetchCurrentSubscription());
        dispatch(fetchUserMessageCount());
      }, 3000);
    } else {
      // Otherwise, execute the calls immediately
      dispatch(fetchCurrentSubscription());
      dispatch(fetchUserMessageCount());
    }

    dispatch(fetchChatSessionHistory());

    return () => {
      dispatch(closeWebSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchChatLog());
  }, [dispatch, currentSessionId]);

  const handleSubmit = useCallback(async (event: string): Promise<void> => {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp && decodedToken.exp * 1000 < new Date().getTime()) {
      await dispatch(checkAuthorization());
    } else {
      dispatch(sendMessage(event));
    }
  }, [dispatch, token]);

  return (
    <div className="App">
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={3000}
      />
      <header className="menu-bg">
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <img src={logo} alt="HackerGpt Lite" />
      </header>

      {showMenu && (
        <nav>
          <div className="navItems">
            <NavContent />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

      <aside className="sideMenu">
        <NavContent />
      </aside>

      {!isWebSocketOpen ? <Loader /> : null}

      <ChatBox handleSubmit={handleSubmit} />

      {(isPaymentCardVisible || isUserCharged) &&
        <SubscriptionModal isOpen={isPaymentCardVisible} />
      }
    </div>
  );
};

export default Home;
