import { memo, useState } from "react";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { createCheckoutSession, fetchProducts } from "../../providers/main";
import { useAppDispatch } from "../../store/store";
import { logout } from "../../store/slices/authSlice";
import { setIsPaymentCardVisible } from "../../store/slices/subscriptionSlice";
import { getToken } from "../../utils/helpers";
import Modal from "../Modal/Modal";
import logo from "../../assets/images/Hacker-GPT-KO.png";
import { ReactComponent as CloseIcon } from "../../assets/images/close_icon.svg";

import styles from "./SubscriptionModal.module.css";

type SubscriptionModalProps = {
  isOpen: boolean;
};

const SubscriptionModal = memo(({ isOpen }: SubscriptionModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const token = useSelector(getToken);

  const dispatch = useAppDispatch();

  const handleSubscribeNow = (): void => {
    setLoading(true);
    const fetchAndCreateSession = async () => {
      try {
        const res = await fetchProducts(token);
        if (res?.products?.length > 0) {
          const session = await createCheckoutSession(token, res.products[0]?.id);
          window.location.href = session.url;
        }
      } catch (error) {
        console.error("Error during fetch or checkout session:", error);
        dispatch(logout());
      } finally {
        setLoading(false);
      }
    };

    fetchAndCreateSession();
  };

  const handleClose = (): void => {
    dispatch(setIsPaymentCardVisible(false));
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className={styles.contentWrapper}>
        <div className={styles.contentHeader}>
          <button className={styles.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.cardContent}>
          <img className={styles.logo} src={logo} alt="HackerGpt Lite" />
          <h3 className={styles.cardTitle}>Monthly Subscription</h3>
          <p className={styles.cardPrice}>$19</p>
          <p className={styles.cardDescription}>Get unlimited access to HackerGPT Lite features, including Leak Search, Phone Number & Email lookups, Dark Web Search, Service Discovery Scans, IP Location and WHOIS lookups and more...</p>
          <button className={styles.cardButton} onClick={handleSubscribeNow}>
            {loading ?
              <PulseLoader
                color={"#fff"}
                style={{ margin: "45px" }}
                loading={true}
                size={10}
                aria-label="Connecting"
                data-testid="loader"
              />
              :
              "Subscribe Now"
            }
          </button>
        </div>
      </div>
    </Modal>
  )
});

export default SubscriptionModal;
