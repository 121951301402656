export type ChatLog = {
  id: number;
  role: string;
  message: string;
  timestamp: string;
  chat_session: number | null;
  images: any[];
}

export type SessionHistory = {
  id: number;
  start_time: string;
  end_time: string | null;
  first_message: string | null;
}

export enum RequestStatuses {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed'
}

type AuthState = {
  currentUser: string;
  token: string;
  userPicture: string;
}

type ChatState = {
  chatLog: ChatLog[];
  currentSessionId: number;
  sessionHistory: SessionHistory[];
  sessionsWithBlockedUserPrompt: number[];
  isWebSocketOpen: boolean;
  fetchChatSessionHistoryStatus: RequestStatuses;
}

type SubscriptionState = {
  isSubscribed: boolean;
  userMessageCount: number;
  numberOfMessagesBeforeCharge: number;
  isPaymentCardVisible: boolean;
}

export type StateSchema = {
  auth: AuthState;
  chat: ChatState;
  subscription: SubscriptionState;
}

export type ThunkConfig = {
  state: StateSchema;
}

export type CheckAuthHandler = () => void;

export type SendMessageHandler = (message: string) => void;

export type StatusChangeHandler = (status: boolean) => void;
