import BotResponse from "../BotResponse";

import styles from "./IntroSection.module.css";

type IntroSectionProps = {
  onSubmit: (event: string) => void;
};

const IntroSection = ({onSubmit}: IntroSectionProps) => {
  const items = [
    [
      { title: 'Check domain', command: 'Check hackergpt.app' },
      { title: 'Scan and Vulnerability Search', command: 'Perform a service discovery scan and find associated CVEs on hackergpt.app' },
      { title: 'Dark Web Intelligence', command: 'Search the dark web for any mentions of HackerGPT' },
    ],
    [
      { title: 'IP / Domain Geolocation', command: 'Find geolocation for hackergpt.app' },
      { title: 'Check Wallet Address', command: 'check 0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a' },
      { title: 'Check Token Validity', command: 'check TRUMP token' },
    ],
    [
      { title: 'Phone Lookup', command: 'Check the owner of this phone number: 646-450-2377' },
      { title: 'Email Discovery', command: 'Find emails for android.com' },
      { title: 'Leaks Search', command: 'Check daryanrayne@gmail.com' },
    ],
  ];

  return (
    <div id={styles.introsection}>
      <h1>
        Welcome to HackerGPT Lite
        <BotResponse response=" - AI Powered OSINT and Discovery Tool" chatLogRef={undefined} />
      </h1>
      <h2>
        An autonomous tool designed to perform OSINT and discovery tasks for penetration testers using a combination of generative AI methodologies.**<span>/</span>
      </h2>
      <p>
        Start your security assessment by giving it a "check [target]" prompt.**<span>/</span>
      </p>

      <div className={styles.separator}></div>

      {
        items.map((item, index) => <div className={styles.chatContainer} key={index}>
          {
            item.map((i, idx) => <fieldset className={styles.emptyChatTile} key={idx} onClick={() => onSubmit(i.command)}>
              <legend className={styles.emptyChatTitle}> ### {i.title}:/</legend>
              <p className={styles.emptyChatDescription}>{i.command} **/</p>
            </fieldset>)
          }
        </div>)
      }

    </div>
  );
};

export default IntroSection;
